import { Dialog } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action } from "../../components/shared/Dropdown";
import { Box, HStack, VStack } from "../../components/utils";
import { useDeletePlan, useGetAllPlanDocs } from "../../queries";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { useError } from "../../store/errorStore";
import { usePermissionStore } from "../../store/permissionStore";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import { EsopPlan, PlanState } from "../../types/EsopPlan";
import { globalFilter, sort } from "../../utils/arrays";
import { determineUserAccessToResource } from "../../utils/auth";
import { getCurrencyType } from "../../utils/currencyFormatter";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import PlanStateChange from "../esopOverview/PlanStateChange";
import { AddOrEditOwnership } from "./AddOrEditOwnership";
import PlanDocument from "./PlanDocument";
import PlanTableAgGrid from "./PlanTableAgGrid";

function PlansTable({
  data,
  isPlaceholderData,
}: {
  data: EsopPlan[];
  isPlaceholderData: boolean;
}) {
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const { authority } = useAuthorizationStore();
  const { permission } = usePermissionStore();
  const errorMessage = useError();
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof EsopPlan | "" | undefined;
    ascending: boolean;
  }>();
  const isUserAdminViewer = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.Read
      ),
    [permission]
  );
  const isUserAdmin = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    [permission]
  );
  const isUserEsopViewer = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.Read
      ),
    [permission]
  );
  data = useMemo(() => {
    if (!data) return [];
    let filterResult = globalFilter(data, globalFilterText, [
      "planName",
      "esopPlanState",
    ]);
    filterResult = filterResult.filter((plan) => plan.planName !== "Total");
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [data, globalFilterText, sortField]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);
  // selection logic
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const onSelectionChange = (item: EsopPlan) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [data, selectAllChecked]);

  const planTableRef = useRef<GridApi | null>(null);
  const [plansCount, setPlansCount] = useState<number>(data.length);

  function handleTableFilterCount(data?: boolean) {
    const plansCount = planTableRef?.current?.getModel().getRowCount();
    if (plansCount !== undefined) {
      setPlansCount(plansCount!);
    }
  }

  useEffect(() => {
    if (planTableRef.current) {
      const gridApi = planTableRef.current.getModel();
    }
  }, [planTableRef, data]);

  useEffect(() => {
    handleTableFilterCount();
  }, [data]);

  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
  }>({ open: false, plan: undefined });
  const [stateChangeDialog, setStateChangeDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
  }>({ open: false, plan: undefined });
  const [uploadDocumentDialog, setUploadDocumentDialog] = useState<{
    open: boolean;
    planId?: string;
  }>({ open: false, planId: "" });
  const [ownershipDialog, setOwnershipDialog] = useState<{
    open: boolean;
    data?: EsopPlan;
  }>({ open: false, data: undefined });
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);
  const { mutate: deletePlan, isLoading } = useDeletePlan();
  let sumTotalOptions = 0;
  let sumGrantedOptions = 0;
  let sumAvailableOptions = 0;
  data?.forEach((plan) => {
    sumTotalOptions += plan?.poolSize || 0;
    sumGrantedOptions += plan?.poolSize || 0 - plan.optionsReserved;
    sumAvailableOptions += plan.optionsReserved;
  });
  function handleAction(plan: EsopPlan, action: Action) {
    if (action.disabled) {
      return;
    }
    if (
      action.name === "Amend Plan" &&
      plan.esopPlanState !== PlanState.AMENDMENT
    ) {
      navigate(`/options/plan/edit/${plan?.esopPlanId}`);
    }
    if (action.name === "View Plan") {
      navigate(`/options/plan/view/${plan?.esopPlanId}`);
    }
    if (action.name === "Update Plan Status" && !action.disabled) {
      setStateChangeDialog({ open: true, plan });
    } else if (action.name === "Upload Plan Document" && !action.disabled) {
      setUploadDocumentDialog({ open: true, planId: plan.esopPlanId });
    } else if (action.name === "Delete Plan" && !action.disabled) {
      setConfirmDialog({ open: true, plan });
    } else if (action.name === "Modify Plan Ownership" && !action.disabled) {
      setOwnershipDialog({ open: true, data: plan });
    }
  }
  function handlePlanDelete(planId: string): void {
    deletePlan(planId, {
      onSuccess: () => {
        toast("Plan Deleted successfully!", { type: "success" });
        setConfirmDialog({ open: false, plan: undefined });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  const { isMobile } = useIsMobile();
  const { data: existingPlanDocs } = useGetAllPlanDocs(
    uploadDocumentDialog.planId ?? ""
  );
  return (
    <Box
      className={`p-8 bg-white border border-borderColor drop-shadow-box rounded-lg ${
        isMobile ? "min-w-[1040px]" : "min-w-min"
      }`}
    >
      <Dialog open={uploadDocumentDialog.open} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <div className="justify-between px-10 text-lg font-medium border-b py-7">
            <h6 className="flex justify-between">
              Upload Documents
              <span
                onClick={() => setUploadDocumentDialog({ open: false })}
                className="cursor-pointer"
              >
                X
              </span>
            </h6>
          </div>
          <PlanDocument
            planId={uploadDocumentDialog.planId || ""}
            existingPlanDocs={existingPlanDocs || []}
            isViewMode={false}
          />
        </div>
      </Dialog>
      <Dialog open={stateChangeDialog.open} maxWidth="lg">
        {stateChangeDialog.plan && (
          <PlanStateChange
            plan={stateChangeDialog.plan}
            onClose={() => setStateChangeDialog({ open: false })}
          />
        )}
      </Dialog>
      <Dialog open={ownershipDialog.open} maxWidth="lg">
        <div className="w-[700px] h-[400px] ">
          <AddOrEditOwnership
            onClose={() => setOwnershipDialog({ open: false })}
            data={ownershipDialog.data}
          />
        </div>
      </Dialog>
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false })}
        maxWidth="lg"
      >
        {confirmDialog.plan && (
          <AlertDialog
            onClose={() => setConfirmDialog({ open: false })}
            open={confirmDialog.open}
            message="Are you sure you want to delete this plan ?"
            onPrimaryAction={() =>
              handlePlanDelete(confirmDialog.plan?.esopPlanId ?? "")
            }
            onSecondaryAction={() =>
              setConfirmDialog({ open: false, plan: undefined })
            }
            loading={isLoading}
          />
        )}
      </Dialog>
      <Toolbar
        length={plansCount}
        globalFilterText={globalFilterText}
        setGlobalFilterText={setGlobalFilterText}
      ></Toolbar>
      <HStack className="justify-between w-full">
        <PlanTableAgGrid
          key={"PlanTable"}
          planTableData={data}
          handleAction={handleAction}
          planTableRef={planTableRef}
          tableFilterCount={handleTableFilterCount}
        />
      </HStack>
    </Box>
  );
}

export default PlansTable;

type ToolbarProps = {
  length: number;
  globalFilterText: string;
  setGlobalFilterText: (text: string) => void;
};

function Toolbar(props: ToolbarProps) {
  return (
    <HStack aria-label="toolbar" className="justify-between">
      <div className="min-w-max ">
        <GenericTableHeader
          heading={"Plans"}
          subHeading={""}
          customClassName={"flex flex-col px-0 lg:justify-start"}
          additionalInfo={<>{props.length} Plans</>}
        ></GenericTableHeader>
      </div>
    </HStack>
  );
}
