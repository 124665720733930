import { Box, VStack } from "../../components/utils";
import { useGetMilestoneTableDetails } from "../../queries/milestone";
import BCHeader from "../../shared/BCHeader";
import useIsMobile from "../../utils/detectDevice";
import { CompleteMilestone } from "./CompleteMilestone";
import { MilestonesTable } from "./MilestonesTable";

export function Milestones() {
  const { isMobile } = useIsMobile();
  const { data: _allMilestoneTableDetails, isPlaceholderData } =
    useGetMilestoneTableDetails();
  return (
    <Box
      className={` p-4 bg-white rounded-lg  ${
        isMobile ? "min-w-[1040px]" : "w-full"
      }`}
    >
      <VStack
        className={`gap-4 rounded-lg ${isPlaceholderData ? "loading" : ""} `}
      >
        <BCHeader
          className="items-baseline"
          bcTitle="Performance Milestones"
          bcDescription="Outline and manage key performance milestones that influence vesting, ensuring alignment with company goals."
        />
        <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
          <MilestonesTable />
        </Box>
      </VStack>
    </Box>
  );
}
