import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Action } from "../../components/shared/Dropdown";
import DropdownButton from "../../components/shared/DropdownButton";
import SummaryCard, { SummaryProps } from "../../components/shared/SummaryCard";
import { Box, HStack, VStack, isEsopViewer } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { usePermissionStore } from "../../store/permissionStore";
import {
  MonetaryEventState,
  MonetaryTransactionType,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import OptionsBuybackAgGridTable from "./OptionBubackAgGridTable";
import { BuybackType } from ".";
import { useGetAllExerciseRequests } from "../../queries/exercise";
import { ExerciseReq } from "../../types/Exercise";

function TransactionSummary() {
  const navigate = useNavigate();
  const isUserEsopViewer = isEsopViewer();

  const {
    data: _buybackOverview,
    isPlaceholderData,
    isFetching,
  } = useGetOptionBuyback();

  const { data: exerciseRequest } = useGetAllExerciseRequests();

  const buybackOverview = _buybackOverview || [];

  function handleAction(action: Action) {
    if (action.name === "Buy Back" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-option-buyback?mode=create");
    }
    if (action.name === "Exercise" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-exercise-event?mode=create");
    }
    if (
      action.name === "Exercise and Liquidate" &&
      !action.disabled &&
      !isUserEsopViewer
    ) {
      navigate("/options/create-exercise-liquidation-flow?mode=create");
    }
    if (action.name === "Liquidate" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-liquidation-flow?mode=create");
    }
  }
  const { isMobile } = useIsMobile();
  const { permission } = usePermissionStore();

  const isCreateDisable = buybackOverview.some(
    (option) =>
      option.eventState !== MonetaryEventState.COMPLETED &&
      option.eventState !== MonetaryEventState.PAUSED
  );
  const summary: SummaryProps[] = [
    {
      name: "Buy Back",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType === MonetaryTransactionType.BUYBACK &&
          (overview.eventState === MonetaryEventState.COMPLETED ||
            overview.eventState === MonetaryEventState.DRAFT ||
            overview.eventState === MonetaryEventState.STARTED ||
            overview.eventState === MonetaryEventState.CLOSED)
      ),
    },
    {
      name: "Exercise and Liquidation",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType ===
            MonetaryTransactionType.EXERCISE_LIQUIDITY &&
          (overview.eventState === MonetaryEventState.COMPLETED ||
            overview.eventState === MonetaryEventState.DRAFT ||
            overview.eventState === MonetaryEventState.STARTED ||
            overview.eventState === MonetaryEventState.CLOSED)
      ),
    },
    {
      name: "Exercise",
      transactionEvent: buybackOverview.filter(
        (overview) =>
          overview.transactionType === MonetaryTransactionType.EXERCISE &&
          (overview.eventState === MonetaryEventState.COMPLETED ||
            overview.eventState === MonetaryEventState.DRAFT ||
            overview.eventState === MonetaryEventState.STARTED ||
            overview.eventState === MonetaryEventState.CLOSED)
      ),
    },
  ];

  const buyBackType: BuybackType[] = [
    {
      name: "Buy Back",
      value: MonetaryTransactionType.BUYBACK,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise and Liquidate",
      value: MonetaryTransactionType.EXERCISE_LIQUIDITY,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise",
      value: MonetaryTransactionType.EXERCISE,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
    {
      name: "Exercise Requests",
      value: null,
      disabled: determineUserAccessToResource(
        permission?.aclList || [],
        Resource.All,
        DefaultAction.All
      ),
    },
  ];

  const [active, setActive] = useState<BuybackType>({
    name: "Buy Back",
    value: MonetaryTransactionType.BUYBACK,
    disabled: false,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTabFromUrl = queryParams.get("activeTab");

  const fetchActiveTabFromUrl = (activeTab: string) => {
    switch (activeTab) {
      case "Exercise and Liquidate": {
        return {
          name: "Exercise and Liquidate",
          value: MonetaryTransactionType.EXERCISE_LIQUIDITY,
          disabled: false,
        };
      }
      case "Buy Back": {
        return {
          name: "Buy Back",
          value: MonetaryTransactionType.BUYBACK,
          disabled: false,
        };
      }
      case "Exercise": {
        return {
          name: "Exercise",
          value: MonetaryTransactionType.EXERCISE,
          disabled: false,
        };
      }
      default: {
        return {
          name: "Buy Back",
          value: MonetaryTransactionType.BUYBACK,
          disabled: false,
        };
      }
    }
  };

  useEffect(() => {
    let activeTab: BuybackType;
    if (activeTabFromUrl === "Exercise Requests") {
      activeTab = {
        name: "Exercise Requests",
        value: null,
        disabled: false,
      };
    } else {
      const activeEvent = _buybackOverview?.find(
        (event) =>
          event.eventState === MonetaryEventState.DRAFT ||
          event.eventState === MonetaryEventState.STARTED ||
          event.eventState === MonetaryEventState.CLOSED
      );

      if (activeEvent && !activeTabFromUrl) {
        switch (activeEvent.transactionType) {
          case MonetaryTransactionType.BUYBACK:
            activeTab = {
              name: "Buy Back",
              value: MonetaryTransactionType.BUYBACK,
              disabled: false,
            };
            break;
          case MonetaryTransactionType.EXERCISE:
            activeTab = {
              name: "Exercise",
              value: MonetaryTransactionType.EXERCISE,
              disabled: false,
            };
            break;
          case MonetaryTransactionType.EXERCISE_LIQUIDITY:
            activeTab = {
              name: "Exercise and Liquidate",
              value: MonetaryTransactionType.EXERCISE_LIQUIDITY,
              disabled: false,
            };
            break;
          default:
            activeTab = {
              name: "Buy Back",
              value: MonetaryTransactionType.BUYBACK,
              disabled: false,
            };
        }
      } else {
        activeTab = fetchActiveTabFromUrl(activeTabFromUrl || "Buy Back");
      }
    }
    setActive(activeTab);
  }, [_buybackOverview, isFetching, activeTabFromUrl]);

  const currentTableData = useMemo(() => {
    let activeTabData: OptionBuyBackDetails[] | ExerciseReq[] = [];
    if (active.name === "Exercise Requests") {
      activeTabData = exerciseRequest?.data || [];
    } else {
      activeTabData = buybackOverview.filter(
        (item) => item.transactionType === active.value
      );
    }
    return activeTabData;
  }, [buybackOverview, active, exerciseRequest]);

  useEffect(() => {
    if (currentTableData) setTableRowCount(currentTableData?.length);
  }, [currentTableData]);

  const gridRef = useRef<GridApi | null>(null);
  const [tableRowCount, setTableRowCount] = useState<number>(
    buybackOverview?.length
  );

  function handleTableFilterCount(data?: number) {
    const employeeCount = gridRef?.current?.getModel().getRowCount();
    if (employeeCount !== undefined) {
      setTableRowCount(employeeCount!);
    }
  }

  useEffect(() => {
    handleTableFilterCount();
  }, [buybackOverview]);

  return (
    <VStack
      className={`p-2 bg-white rounded ${isPlaceholderData ? "loading" : ""} `}
    >
      <HStack className="justify-between mx-2">
        <VStack>
          <p className="text-sm font-medium text-gray-dark">
            Transaction Summary
          </p>
        </VStack>
        {!isUserEsopViewer && (
          <HStack className="items-center justify-between rounded ">
            <AddTransaction
              actions={[
                {
                  name: "Buy Back",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
                {
                  name: "Exercise and Liquidate",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
                {
                  name: "Exercise",
                  disabled:
                    isCreateDisable &&
                    determineUserAccessToResource(
                      permission?.aclList || [],
                      Resource.All,
                      DefaultAction.All
                    ),
                },
              ]}
              onAction={(action) => handleAction(action)}
            />
          </HStack>
        )}
      </HStack>
      {/* summary cards */}
      <HStack
        className={`grid gap-4 md:grid-cols-${summary.length} sm:grid-cols-1 lg:grid-cols-${summary.length}`}
      >
        {summary.map((card, index) => (
          <SummaryCard key={index} {...card} />
        ))}
      </HStack>

      <HStack className="justify-between px-4 pt-4 overflow-auto">
        <HStack>
          {active.name !== "Exercise Requests" ? (
            <div>
              <p className="text-lg font-medium text-gray-dark">
                {active.value === MonetaryTransactionType.EXERCISE
                  ? "Option Exercise Overview"
                  : active.value === MonetaryTransactionType.BUYBACK
                  ? "Option Buy Back Overview"
                  : active.value === MonetaryTransactionType.LIQUIDATION
                  ? "Option Liquidation Overview "
                  : "Option Exercise and Liquidation"}
              </p>
              <p className="text-xs font-medium text-[#B5B5C3]">
                {tableRowCount} results
              </p>
            </div>
          ) : (
            <div>
              <p className="text-lg font-medium text-gray-dark">
                Option Exercise Requests
              </p>
              <p className="text-xs font-medium text-[#B5B5C3]">
                {" "}
                {tableRowCount} results
              </p>
            </div>
          )}
        </HStack>
        <HStack className="gap-4">
          {buyBackType.map((type) => (
            <Box
              key={type.name}
              onClick={() => {
                setActive(type);
                if (type.name === "Exercise Requests") {
                  navigate(
                    "/options/esopTransactions/exerciseRequests?activeTab=Exercise%20Requests"
                  );
                } else {
                  navigate(`/options/esopTransactions?activeTab=${type.name}`);
                }
              }}
              className={`text-xs3 font-semibold cursor-pointer py-1 ${
                type.name === active?.name
                  ? "border-b-2 border-solid border-red-500 text-red-500"
                  : "text-gray-201 "
              } `}
            >
              <p className={`${isPlaceholderData ? "loading" : ""}`}>
                {type.name}
              </p>
            </Box>
          ))}
        </HStack>
      </HStack>

      <Box
        className={` p-4 bg-white rounded-lg  ${
          isMobile ? "min-w-[1040px]" : "w-full"
        }`}
      >
        <OptionsBuybackAgGridTable
          optionsBuyBackData={currentTableData as OptionBuyBackDetails[]}
          exerciseRequestData={currentTableData as ExerciseReq[]}
          selectedTab={active.name}
          gridRef={gridRef}
          tableFilterCount={handleTableFilterCount}
        />
      </Box>
    </VStack>
  );
}

export default TransactionSummary;

export function AddTransaction({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  const { isMobile } = useIsMobile();
  return (
    <DropdownButton
      className="items-center"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div
          className={`${
            isMobile ? "text-xs whitespace-nowrap" : ""
          } flex items-center rounded`}
        >
          <p>Add ESOP Transaction</p>
          <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
        </div>
      )}
    />
  );
}
