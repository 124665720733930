/* eslint-disable etc/no-commented-out-code */
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Icon } from "@iconify/react";
import _ from "lodash";

import { useAuthStore, useCompanyStore } from "../../store";
import { FilterConditionModel } from "../../api/AgGridCache";
import { useDeleteTableState } from "../../queries/AgGridCacheQueries";
import Tooltip from "./Tooltip";

interface SavingStatusIconProps {
  isLoading: boolean;
  onClick: () => void;
}
const SavingStatusIcon: React.FC<SavingStatusIconProps> = ({
  isLoading,
  onClick,
}) => {
  const [showSaved, setShowSaved] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    let timer: any;
    if (!isLoading) {
      timer = setTimeout(() => {
        setShowSaved(true);
        setTimeout(() => setShowSaved(false), 2000);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  const handleIconClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 200);
    onClick();
  };

  return (
    <div className="flex items-center space-x-2">
      <Icon
        icon={"mdi:table-sync"}
        width="512"
        height="512"
        className={`w-6 h-6 cursor-pointer text-gray-500  ${
          clicked ? "scale-110 text-blue-600" : ""
        }`}
        onClick={handleIconClick}
      />
    </div>
  );
};
interface CloudSettingProps {
  tableId: string;
  isLoading: boolean;
  filterCondition: FilterConditionModel;
  setFilterCondition: (func: any) => void;
  gridApi?: any;
}

const CloudSetting: React.FC<CloudSettingProps> = ({
  tableId,
  isLoading,
  filterCondition,
  setFilterCondition,
  gridApi,
}) => {
  const [show, setShown] = useState<boolean>(false);
  const { mutate: deleteTableState } = useDeleteTableState();
  const { companyData } = useCompanyStore();
  const companyIdget = companyData?.id || "";

  const handleClearAllStates = (): void => {
    deleteTableState(
      { tableId, companyId: companyIdget },
      {
        onSuccess: () => {
          if (gridApi) {
            gridApi.current.api.setFilterModel(null);
            gridApi.current.columnApi.resetColumnState();

            gridApi.current.api.refreshCells({ force: true });
          }
        },
      }
    );
  };
  return (
    <div>
      <Tooltip
        text={
          "Reset Table View\nClick here to reset the table to the default view."
        }
        _className="bg-[rgba(0,0,0,0.5)] text-white w-[350px] whitespace-pre-wrap px-3 rounded"
      >
        <SavingStatusIcon
          isLoading={isLoading}
          onClick={handleClearAllStates}
        />
      </Tooltip>
    </div>
  );
};
export default CloudSetting;
