import { LinearProgress } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import {
  CellClassParams,
  CellEditorSelectorResult,
  ColDef,
  GridApi,
  ICellEditorParams,
  SideBarDef,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  CellRenderer,
  HStack,
  ValidityCellRenderer,
  VStack,
} from "../../components/utils";
import convertToBase64 from "../../utils/convertToBase64";
import { useAddTaxExcel, useEmployees } from "../../queries";
import { useCompanyStore } from "../../store";
import { downloadS3File } from "../../utils/DownloadFile";
import {
  GrantValidation as ExcelValidation,
  ValidationObj,
} from "../../types/Grant";
import { ValidationResponse } from "../../types/Employee";

interface GrantUploadProps {
  data: ValidationObj[][];
  onSubmit: (data: ValidationObj[][]) => void;
  onDiscard: () => void;
  operationInProgress: boolean;
}

function TaxImport() {
  const { companyData } = useCompanyStore();
  const { mutate: addTaxExcel } = useAddTaxExcel();
  const [validationData, setValidationData] = useState<ExcelValidation>({
    fileUploaded: false,
    fileValidated: false,
    hasErrors: false,
    data: [],
  });
  const [showStatus, setShowStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: any) => {
    const file = e.target?.files[0];
    if (!file) return;

    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setShowStatus(true);
      convertToBase64(file).then((data) => {
        addTaxExcel(
          { file: data as string },
          {
            onSuccess: (data) => {
              setShowStatus(false);
              setErrorMessage(null);
              toast("Tax details updated successfully", { type: "success" });
              navigate("/options/allEmployees");
            },
            onError: (err) => {
              setShowStatus(false);
              if (fileRef.current) fileRef.current.value = "";
              const errorData = err?.response?.data?.errors;
              if (errorData && typeof errorData === "object")
                setValidationData({
                  ...validationData,
                  hasErrors: true,
                  data: errorData,
                  fileUploaded: true,
                });
              else toast(`Failed to upload ${errorData}`, { type: "error" });
            },
          }
        );
      });
    } else {
      setErrorMessage(
        `Invalid file type. Allowed types are ${allowedFileExtensions.join(
          ", "
        )}`
      );
      e.target.value = "";
    }
  };
  const importValidatedData = (data: ValidationObj[][]) => {
    setShowStatus(true);
    addTaxExcel(
      { validatedTaxDetail: data },
      {
        onSuccess: (data) => {
          setShowStatus(false);
          setErrorMessage(null);
          toast("Tax details updated successfully", { type: "success" });
          navigate("/options/allEmployees");
        },
        onError: (err) => {
          setShowStatus(false);
          if (fileRef.current) fileRef.current.value = "";
          const errorData = err?.response?.data?.errors;
          if (errorData && typeof errorData === "object")
            setValidationData({
              ...validationData,
              hasErrors: true,
              data: errorData,
              fileUploaded: true,
            });
          else toast(`Failed to upload ${errorData}`, { type: "error" });
        },
      }
    );
  };
  const downloadTemplate = (e: any) => {
    e.preventDefault();
    downloadS3File(
      "https://equion-dev.s3.us-west-2.amazonaws.com/Upload_template/taxUploadTemplate.xlsx",
      "TaxUploadTemplate.xlsx"
    );
  };
  const discard = () => {
    setValidationData({
      fileValidated: false,
      hasErrors: false,
      data: [],
      fileUploaded: false,
    });
  };
  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
      <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
        <h6 className="flex-1">Upload Tax</h6>
        <ButtonPrimary onClick={downloadTemplate}>
          Download Template
        </ButtonPrimary>
      </HStack>
      <VStack className="w-full h-full gap-6 px-2 py-4 bg-white flex-grow">
        <Box className="flex justify-center w-full px-10 text-lg font-medium">
          <h6>Upload your Tax Excel</h6>
        </Box>
        {!validationData.fileUploaded && (
          <>
            <HStack className="gap-8 pt-8 mx-auto">
              <div className="flex-1">
                <input
                  ref={fileRef}
                  className="w-full form-input"
                  type="file"
                  accept=".xlsx,.xls,.ods"
                  onChange={handleChange}
                />
              </div>
            </HStack>
            {showStatus && (
              <LinearProgress className="w-full" variant="indeterminate" />
            )}
            <VStack className="text-xxs justify-start text-start pt-2">
              <li>Download the tax details upload template</li>
              <li>Fill in the values appropriately</li>
              <li>Upload the tax Details file</li>
              <li>Details will be updated if there are no error(s)</li>
            </VStack>
          </>
        )}
        {validationData.fileUploaded && validationData.hasErrors && (
          <>
            <TaxUploadGrid
              data={validationData.data}
              onDiscard={discard}
              onSubmit={(data) => {
                importValidatedData(data);
              }}
              operationInProgress={showStatus}
            />
          </>
        )}
        {errorMessage && (
          <Box className="mt-4 p-4 border border-red-500 bg-red-100 text-red-800 rounded">
            <h6 className="font-bold">Error:</h6>
            <p>{errorMessage}</p>
          </Box>
        )}
      </VStack>
    </VStack>
  );
}
const TaxUploadGrid = (props: GrantUploadProps) => {
  const {
    data: _validationData,
    onDiscard,
    onSubmit,
    operationInProgress: inProgress,
  } = props;
  const gridApi = useRef<GridApi | any>(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [validationData, columnNames] = useMemo(() => {
    let err = false;
    let columnNames: string[] = [];
    const validationData = _validationData.map((dataItem) => {
      let rowHasErr = false;
      dataItem.forEach((data) => {
        if (data.error) {
          err = true;
          rowHasErr = true;
        }
      });
      return [...dataItem];
    });
    if (validationData.length > 0) {
      const keys = _.maxBy(validationData, Object.keys);
      columnNames = [
        "validity",
        "hrId",
        "taxSlab",
        "surcharge",
        "cess",
        "regime",
      ];
    }
    if (err) {
      toast("Please correct the errors before proceeding", {
        type: "error",
        autoClose: 2000,
      });
      setHasErrors(true);
    }
    return [validationData, columnNames];
  }, [_validationData]);
  useEffect(() => {
    if (gridApi.current) {
      gridApi.current?.api.setRowData(validationData);
    }
  }, [gridApi.current]);
  useEffect(() => {
    setOperationInProgress(inProgress);
  }, [inProgress]);

  const [canSelectedBeImported, setCanSelectedBeImported] =
    useState(inProgress);
  const checkErrors = (data: ValidationResponse[][], setErrors = true) => {
    const err = data
      .filter((dataItem) =>
        dataItem.find((dataEntity) => dataEntity.name !== "validity")
      )
      .some((dataItem) => dataItem.some((data) => data.valid === false));
    if (setErrors) {
      if (err) {
        setHasErrors(true);
      } else {
        setHasErrors(false);
      }
    }

    return err;
  };
  const proceedToImport = (all = true) => {
    setOperationInProgress(true);
    let data: ValidationResponse[][] = [];
    if (all) {
      gridApi.current?.api?.forEachNode((node: any) => {
        data.push(node.data);
      });
    } else {
      const selectedRows = gridApi.current?.api?.getSelectedRows();
      if (selectedRows.length === 0) {
        toast("No Rows Selected", {
          autoClose: 2000,
          type: "error",
        });
        setOperationInProgress(false);
        return;
      }
      data = selectedRows;
    }
    onSubmit(data);
  };
  const [operationInProgress, setOperationInProgress] = useState(false);
  const cellEditorSelector: (
    params: ICellEditorParams<any>
  ) => CellEditorSelectorResult | undefined = (
    params: ICellEditorParams<any>
  ) => {
    const field = params.colDef.field;
    switch (field) {
      default: {
        return undefined;
      }
    }
  };
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
      ],
    }),
    []
  );
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      autoHeight: true,
      enableMenu: false,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      menuTabs: ["filterMenuTab"],
      suppressColumnsToolPanel: true,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const getCellStyle = (params: CellClassParams<any, any>) => {
    const colId = params.column.getColId();
    if (colId === "validity") {
      if (params.data.find((dataItem: { valid: boolean }) => !dataItem.valid))
        return { color: "red", backgroundColor: "unset" };
      return { color: "black", backgroundColor: "unset" };
    } else {
      return params?.value?.valid
        ? { color: "black", backgroundColor: "unset" }
        : { color: "red", backgroundColor: "unset" };
    }
  };
  function setValueAndResetCell(params: ValueSetterParams) {
    const dataItem = params?.data;
    if (params.newValue === undefined || params.newValue === null) return false;
    dataItem.forEach((data: any) => {
      if (data.name === params.colDef.field) {
        if (data.value !== params.newValue) {
          data.value = params.newValue;
          data.valid = true;
          data.error = null;
        }
      }
    });
    params.node?.setData(dataItem);
    const data: any[] = [];
    gridApi.current.api?.forEachNode((node: any) => {
      data.push(node.data);
    });
    checkErrors(data);
    gridApi.current?.api.refreshCells();
    return true;
  }
  function isRowValid(rowData: ValidationResponse[]) {
    const err = rowData
      ?.filter((dataItem) => dataItem.name !== "validity")
      ?.some((dataItem) => !dataItem.valid);
    return !err;
  }
  const valueGetter = (params: ValueGetterParams<any, any>) => {
    const colId = params.column.getColId();
    const data = params?.data?.find(
      (dataItem: any) => dataItem.name === colId
    ) || { value: undefined, valid: true };
    if (colId === "validity") {
      const isRowDataValid = isRowValid(params?.data);
      const validityValue: ValidationResponse = {
        name: "validity",
        valid: isRowDataValid,
        value: isRowDataValid ? "Valid" : "Invalid",
        error: isRowDataValid ? null : "Validation Error",
      };
      return validityValue;
    }
    return data;
  };
  const columnDefs: ColDef[] = useMemo(
    () =>
      columnNames?.map((item, index) => {
        const colDef: ColDef = {
          headerName: item,
          valueGetter,
          field: item,
          suppressColumnsToolPanel: true,
          cellRenderer:
            item === "validity" ? ValidityCellRenderer : CellRenderer,
          cellRendererParams: ({ value }: { value: any }) => value,
          cellStyle: getCellStyle,
          valueSetter: setValueAndResetCell,
          filterValueGetter: (params) => {
            const data = params.data?.find(
              (dataItem: any) => dataItem.name === item
            );
            if (item === "validity") {
              return isRowValid(params?.data || []) ? "Valid" : "Invalid";
            }
            return data?.value;
          },
          cellEditorParams: (params: any) => params.value,
          headerClass: "text-sm font-normal capitalize",
          cellDataType: "string",
          editable: item !== "validity",
          pinned: item === "validity" ? "left" : undefined,
        };
        return colDef;
      }),
    [columnNames]
  );

  const onGridReady = (params: any) => {
    gridApi.current = params;
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    params.columnApi.getAllColumns().map((column: any) => column.colId);
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();
    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack className="justify-between w-full h-full bg-white rounded-md min-h-[500px]">
      <li className="text-xxs">
        Validity of Entry is denoted in the `Validity` Column
      </li>
      <li className="text-xxs">Make changes if necessary</li>
      <li className="text-xxs">Click on Import All or Import Selected</li>
      <li className="text-xxs">
        For Non-Residents please select the New Tax Regime
      </li>
      <div className="py-4 ag-theme-material" style={{ height: 500 }}>
        <AgGridReact
          key={"TaxDetailsUpload"}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={"multiple"}
          rowClass={"border-t border-dashed"}
          suppressRowClickSelection={true}
          onSelectionChanged={() => {
            const selectedRows = gridApi.current?.api?.getSelectedRows();
            if ((selectedRows || []).length === 0) {
              setCanSelectedBeImported(false);
              return;
            }
            const err = checkErrors(selectedRows, false);
            if (err) {
              setCanSelectedBeImported(false);
            } else {
              setCanSelectedBeImported(true);
            }
          }}
          onFilterChanged={onAgGridFilterChanged}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          suppressRowTransform={true}
          animateRows={true}
          pagination={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={true}
          sideBar={sideBar}
          rowData={validationData}
          rowMultiSelectWithClick={true}
          stopEditingWhenCellsLoseFocus={true}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
          }
        />
      </div>
      <HStack className="text-xxs justify-end -mt-4 mb-4">
        * All the tax values are in percentages, until mentioned otherwise
      </HStack>
      <HStack className="justify-end gap-4 p-3">
        <ButtonPrimary1
          type="reset"
          className="text-red-500"
          onClick={() => {
            onDiscard();
          }}
        >
          Discard
        </ButtonPrimary1>
        {!operationInProgress && (
          <ButtonPrimary disabled={hasErrors} onClick={() => proceedToImport()}>
            Import All
          </ButtonPrimary>
        )}
        {operationInProgress && (
          <Icon
            className="animate-spin text-orange-501"
            icon="lucide:loader-2"
            width={36}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default TaxImport;
