import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Icon } from "@iconify/react";

import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import SearchDropDown from "../../../components/shared/SearchDropdown";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../../store/useGrantDetailsStore";
import { useValuationDetails } from "../../../queries/Valuation";
import { Valuation, ValuationSource } from "../../../types/ValuationType";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { Select } from "../../../components/shared/Select";
import { useGetGrantPPS } from "../../../queries";
import { useCompanyStore } from "../../../store";
import { getFormattedValue } from "../../../utils/string";

interface GrantByOptionsProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

enum ChangedValue {
  Options = "Options",
  Amount = "Amount",
  Valuation = "Valuation",
  Share = "Share",
}

export const GrantPageCard = ({
  header,
  value,
}: {
  header: string;
  value: string;
}) => (
  <VStack className="p-4 border border-gray-200 rounded-lg shadow-custom">
    <p className="p-1 text-sm font-normal form-label">{header}</p>
    <p className="pl-4 pr-8 text-base font-normal text-center">{value}</p>
  </VStack>
);

const GrantByOptionsPage = (props: GrantByOptionsProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const {
    data: _valuationData,
    isPlaceholderData,
    isFetched,
  } = useValuationDetails();
  const [valuationData, setValuationData] = useState<Valuation[]>([]);
  const [errors, setErrors] = useState<any>({});
  useEffect(() => {
    if (_valuationData && _valuationData.length > 0 && !isPlaceholderData) {
      const companyValuations = _valuationData.filter(
        (v) => v.valuationSource === ValuationSource.COMPANY
      );
      setValuationData([...companyValuations]);
    }
  }, [isFetched, _valuationData, isPlaceholderData]);

  const { companyData } = useCompanyStore();

  function handleChange(value: number, changedValue: ChangedValue) {
    if (changedValue === ChangedValue.Options) {
      data.setOptionsGranted(value);
      if (value >= 0 && (data.sharePrice || 0) >= 0) {
        data.setValueOfOptions(
          parseFloat(
            (
              value *
              ((data.sharePrice || 0) * (data.plan?.conversionShares || 1))
            ).toFixed(4)
          )
        );
      }
    } else if (changedValue === ChangedValue.Amount) {
      data.setAmount(value);
      if (data.amount >= 0 && data.sharePrice >= 0) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat(
                (
                  value /
                  ((data.sharePrice || 0) * (data.plan?.conversionShares || 1))
                ).toFixed(4)
              )
            : parseInt(
                (
                  value /
                  ((data.sharePrice || 0) * (data.plan?.conversionShares || 1))
                ).toString(),
                10
              )
        );
      }
    } else if (changedValue === ChangedValue.Share) {
      data.setSharePrice(value);
      if (
        value >= 0 &&
        (data.optionsGranted || 0) >= 0 &&
        data.grantedBy === GrantedBy.OPTIONS
      ) {
        data.setValueOfOptions(
          parseFloat(
            (
              data.optionsGranted *
              ((value || 0) * (data.plan?.conversionShares || 1))
            ).toFixed(4)
          )
        );
      }
      if (
        value >= 0 &&
        (data.amount || 0) >= 0 &&
        data.grantedBy === GrantedBy.AMOUNT
      ) {
        data.setOptionsGranted(
          data?.plan.isFractional
            ? parseFloat(
                (value === 0
                  ? 0
                  : data.amount / (value * (data.plan?.conversionShares || 1))
                ).toFixed(4)
              )
            : parseInt(
                (value === 0
                  ? 0
                  : data.amount / (value * (data.plan?.conversionShares || 1))
                ).toString(),
                10
              )
        );
      }
    }
  }

  const validationSchema = Yup.object().shape({
    noOfOptions: Yup.number()
      .required("No of Options is required")
      .min(
        data?.plan?.isFractional ? 0.0001 : 1,
        `No of Options must be greater than ${
          data?.plan?.isFractional ? 0.0001 : 1
        }`
      )
      .max(
        data?.plan?.optionsReserved,
        "No. of Options must be lesser than the Options Available"
      ),
    grantPrice: Yup.number()
      .required("Grant Price is required")
      .when("plan.planType", {
        is: "RSU",
        then: Yup.number().min(0, "Grant Price cannot be less than 0"),
        otherwise: Yup.number().min(
          0.0001,
          "Grant Price must be greater than 0"
        ),
      }),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        noOfOptions: data.optionsGranted || 0,
        grantedBy: data.grantedBy,
        grantPrice: data.grantPrice || 0,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }
  const [inputValue, setInputValue] = useState("");

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Grant Information
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          {data.grantedBy === GrantedBy.OPTIONS
            ? "Enter the details for the grant, including the number of options to be granted, based on the available options, conversion ratio, and plan type."
            : "Enter the details of the grant, including the grant amount, based on the available options, conversion ratio, and plan type."}
        </HStack>
      </VStack>
      <HStack className="gap-4 p-4">
        <GrantPageCard
          header="Options Available"
          value={(data?.plan?.optionsReserved || 0).toLocaleString(
            currencyType,
            {
              maximumFractionDigits: 4,
            }
          )}
        />
        <GrantPageCard header="Conversion Ratio" value={data.conversionRatio} />
        <GrantPageCard header="Plan Type" value={data?.plan?.planType} />
      </HStack>
      <VStack className="gap-4 px-6">
        <HStack></HStack>
        <HStack className="items-center justify-between gap-8 pr-40 text-center"></HStack>
        <HStack className="gap-8 ">
          {data.grantedBy === GrantedBy.OPTIONS && (
            <div className="flex-1">
              <Label className="text-sm font-normal">No. of Options</Label>
              <Input
                type="number"
                placeholder={
                  data.optionsGranted <= 0 && !inputValue
                    ? "Enter no. of options"
                    : ""
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const isFractionalAllowed = data?.plan.isFractional;
                  if (
                    (isFractionalAllowed &&
                      (/^\d*\.?\d*$/.test(value) || value === "")) ||
                    (!isFractionalAllowed &&
                      (/^\d*$/.test(value) || value === ""))
                  ) {
                    setInputValue(value);
                    const parsedValue = isFractionalAllowed
                      ? value === ""
                        ? 0
                        : parseFloat(Number(value).toFixed(4))
                      : value === ""
                      ? 0
                      : parseInt(value, 10);

                    handleChange(parsedValue, ChangedValue.Options);
                  }
                }}
                value={
                  inputValue ||
                  (data.optionsGranted !== 0 ? data.optionsGranted : "")
                }
              />
              {errors.noOfOptions && <Error text={errors.noOfOptions} />}
            </div>
          )}
          {data.grantedBy === GrantedBy.AMOUNT && (
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Grant Amount ({currencySymbol})
              </Label>
              <Input
                type="number"
                placeholder={`${data.amount > 0 ? "" : "Enter the amount"}`}
                onChange={(e) => {
                  const value = parseFloat(Number(e.target.value).toFixed(4));
                  handleChange(value, ChangedValue.Amount);
                }}
                value={data.amount === 0 ? "" : data.amount}
              />
            </div>
          )}
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Grant Price ({currencySymbol})
            </Label>
            <Input
              type="number"
              onChange={(e) => data.setGrantPrice(parseFloat(e.target.value))}
              value={data.grantPrice === 0 ? "" : data.grantPrice}
              placeholder={`${data.grantPrice > 0 ? "" : "Enter Grant Price"}`}
              min={0}
            />
            {errors.grantPrice && <Error text={errors.grantPrice} />}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Reports</Label>
            <Select
              onChange={(e) => {
                if (companyData?.companyMetaData.typeOfTheCompany === "PUBLIC")
                  return;
                const valuation = valuationData.find(
                  (v) => v.id === e.target.value
                );
                data.setValuationId(e.target.value);
                handleChange(valuation?.pps || 0, ChangedValue.Share);
              }}
              placeholder="--Select--"
              options={valuationData}
              value={data.valuationId}
              textGetter={(e) => e.name}
              valueGetter={(e) => e.id}
              className={""}
              disabled={
                companyData?.companyMetaData.typeOfTheCompany === "PUBLIC"
              }
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Share Price ({currencySymbol})
            </Label>
            <Input
              type="number"
              disabled={data.valuationId !== ""}
              placeholder={`${data.sharePrice > 0 ? "" : "Enter share price"}`}
              min={0}
              onChange={(e) => {
                const value = parseFloat(
                  Number(e.target.value === "" ? "0" : e.target.value).toFixed(
                    4
                  )
                );
                handleChange(value, ChangedValue.Share);
              }}
              value={data.sharePrice === 0 ? "" : data.sharePrice}
            />
          </div>
        </HStack>
        {data.grantedBy === GrantedBy.OPTIONS && data.grantPrice > 0 && (
          <HStack className="items-start gap-8">
            {data.grantPrice > 0 && data.optionsGranted > 0 && (
              <div className="flex items-center flex-1 gap-x-2">
                <Label className="text-sm font-normal">
                  Cost of Grant ({currencySymbol}):
                </Label>
                <p className="mb-2 text-sm">
                  {(data.optionsGranted * data.grantPrice).toLocaleString(
                    currencyType
                  )}
                </p>
              </div>
            )}
            {data.sharePrice > 0 && data.optionsGranted > 0 && (
              <div className="flex items-center flex-1 gap-x-2">
                <Label className="text-sm font-normal">
                  Value of Options ({currencySymbol}):
                </Label>
                <p className="mb-2 text-sm">
                  {(
                    data.optionsGranted *
                    data.sharePrice *
                    (data.plan?.conversionShares || 1)
                  ).toLocaleString(currencyType)}
                </p>
              </div>
            )}
          </HStack>
        )}

        {data.grantedBy === GrantedBy.AMOUNT &&
          data.grantPrice > 0 &&
          data.sharePrice > 0 &&
          data.amount > 0 && (
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">No. of Options</Label>
                <Input
                  type="number"
                  placeholder={`${
                    data.optionsGranted <= 0 ? "Enter no. of options" : ""
                  }`}
                  onChange={(e) => {}}
                  disabled={true}
                  value={data.optionsGranted}
                />
                {errors.noOfOptions && <Error text={errors.noOfOptions} />}
              </div>
              <div className="flex items-center flex-1 mt-6 gap-x-2">
                <Label className="text-sm font-normal">
                  Cost of Grant ({currencySymbol}):
                </Label>
                <p className="mb-2 text-sm">
                  {(data.optionsGranted * data.grantPrice).toLocaleString(
                    currencyType
                  )}
                </p>
              </div>
            </HStack>
          )}

        {data.sharePrice > 0 && data.grantPrice > 0 && data.optionsGranted > 0 && (
          <HStack className="p-2 text-blue-800 bg-blue-100 rounded">
            <div className="pt-0">
              <Icon
                icon="material-symbols:info-outline-rounded"
                height={24}
                width={24}
                className="w-full text-xs font-medium text-blue-800 rounded cursor-pointer"
              />
            </div>
            <div className="px-2 pt-1">
              {`The Net Value of the Grant is
              ${formatCurrency(
                data.optionsGranted *
                  data.sharePrice *
                  (data.plan?.conversionShares || 1) -
                  data.optionsGranted * data.grantPrice,
                currencyType
              )}
              which is arrived at by subtracting "Cost of Grant" from the "Value
              of Options".`}
            </div>
          </HStack>
        )}

        <HStack className="justify-between pt-4">
          <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default GrantByOptionsPage;
