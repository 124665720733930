import { Form, Formik, FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Switch } from "@mui/material";
import { Input, Label, TextArea } from "../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  getCompanyName,
  HStack,
  VStack,
} from "../components/utils";
import { CorporateActionDetails } from "../types/CompanyProfile";
import {
  useGenerateBonusReport,
  useUploadDocumentTemplate,
} from "../queries/onBoarding";
import { getCurrencySymbol } from "../utils/currencyFormatter";
import { formatDate } from "../utils/date";
import { BonusSummaryReport } from "../pages/companyProfile/CorporateActionDetailsCard";
import Tooltip, { TooltipProps } from "../components/shared/Tooltip";
import { downloadBlobObject } from "../utils/DownloadFile";
import { useCompanyStore } from "../store";
import { CollapsibleComponent } from "../components/shared/Collapsible";

export function AddOrEditBonusDetails({
  corporateAction,
  mode,
  onClose,
  setDialog,
}: {
  corporateAction?: CorporateActionDetails;
  mode: "AddBonus" | "EditBonus";
  onClose: () => void;
  setDialog: Function;
}) {
  const initialValues: CorporateActionDetails = {
    id: corporateAction?.id,
    name: corporateAction?.name || "",
    date: corporateAction?.date ? formatDate(corporateAction?.date || "") : "",
    ratio: corporateAction?.ratio || 1,
    bonusStrikePrice: corporateAction?.bonusStrikePrice,
    type: corporateAction?.type || "BONUS",
    roundingMethodology: "",
    state: corporateAction?.state || "DRAFT",
    settings: corporateAction?.settings,
    bonusTemplates: corporateAction?.bonusTemplates,
    note: corporateAction?.note || "",
  };
  const { mutate: generateBonusReport } = useGenerateBonusReport();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Bonus Name"),
    date: Yup.date().required("Bonus Date"),
    ratio: Yup.number().positive().required("Bonus Ratio Required"),
    bonusStrikePrice: Yup.number()
      .positive()
      .required("Bonus Strike Price Required"),
  });

  const handleSubmit = (values: CorporateActionDetails) => {
    if (disableForm) return;
    setIsSaveLoading(true);
    generateBonusReport(values, {
      onSuccess: (data) => {
        toast("Bonus Details Updated Successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
        setIsSaveLoading(false);
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorRaw;
        toast(errMsg || "Error Creating Bonus Details", {
          type: "error",
          autoClose: 2000,
        });
        setIsSaveLoading(false);
      },
    });
  };

  const currencySymbol = getCurrencySymbol();

  function handleApproveBonus(values: CorporateActionDetails) {
    if (disableForm) return;
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) return;
    setDialog({ open: true, data: values, mode: "ApproveBonus" });
  }

  const [isBonusGrantLetterLoading, setIsBonusGrantLetterLoading] =
    useState(false);

  const { mutate: uploadDocument } = useUploadDocumentTemplate();

  const { companyData } = useCompanyStore();

  const [accordionOpened, setAccordionOpened] = useState<
    "basic details" | "settings" | "documents" | ""
  >("basic details");

  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    setDisableForm(corporateAction?.state === "APPROVED");
  }, []);

  async function handleBonusGrantLetterUpload(
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<CorporateActionDetails>
  ) {
    if (disableForm) return;
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    if (file) {
      const companyName = companyData?.name || "";
      const document = {
        file,
        key: `/${companyName}/Corporate_Action_${
          corporateAction?.name
        }/Bonus-Grant-Letter_${new Date().toISOString()}.docx`,
        contentType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        templateName: "Bonus Grant Letter",
        companyId: companyData?.id || "",
        templateType: "BONUS_GRANT_LETTER",
        entityType: "CORPORATE_ACTION",
        entityId: corporateAction?.id || "",
      };
      uploadDocument(document, {
        onSuccess: (data: { s3Path: string }) => {
          formik.setFieldValue(
            "bonusTemplates.bonusGrantLetterTemplateUrl",
            data.s3Path
          );
          toast("Grant Letter Template uploaded Successfully", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorsValidation?.errorRaw;
          if (errMsg) {
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  }

  return (
    <>
      <div className="">
        <div className="px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            <p className="font-bold">Bonus </p>
            <span onClick={() => onClose()} className="cursor-pointer">
              X
            </span>{" "}
          </h6>
        </div>
        <VStack className="w-full px-10 py-5 gap-9">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <VStack className="gap-6">
                  <CollapsibleComponent
                    label={"Bonus Event Details"}
                    labelDetail={""}
                    isOpen={accordionOpened !== "basic details"}
                    onClick={() => setAccordionOpened("basic details")}
                  >
                    <VStack className="gap-3 lg:w-[700px]">
                      <HStack className="justify-between gap-4">
                        <VStack className="w-1/2">
                          <HStack className="items-center">
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Bonus Name
                            </Label>
                            <VStack className="gap-1 w-full">
                              <Input
                                type="text"
                                {...formik.getFieldProps("name")}
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                              <div>
                                {formik.touched.name && formik.errors.name && (
                                  <Error text={formik.errors.name} />
                                )}
                              </div>
                            </VStack>
                          </HStack>
                        </VStack>
                        <VStack className="w-1/2">
                          <HStack className="items-center">
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Bonus Date
                            </Label>
                            <VStack className="gap-1 w-full">
                              <Input
                                type="date"
                                {...formik.getFieldProps("date")}
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                              <div>
                                {formik.touched.date && formik.errors.date && (
                                  <Error text={formik.errors.date} />
                                )}
                              </div>
                            </VStack>
                          </HStack>
                        </VStack>
                      </HStack>
                      <HStack className="space-between gap-4">
                        <VStack className="w-1/2">
                          <HStack className="items-center">
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Bonus Ratio
                            </Label>
                            <VStack className="gap-1 w-full">
                              <HStack className="items-center form-input px-0 py-0">
                                <p className="text-sm font-normal pl-3 whitespace-nowrap">
                                  1 :
                                </p>
                                <Input
                                  type="number"
                                  {...formik.getFieldProps("ratio")}
                                  disabled={disableForm}
                                  className={`${
                                    disableForm ? "cursor-not-allowed" : ""
                                  } pl-2`}
                                />
                              </HStack>
                              {formik.touched.ratio && formik.errors.ratio && (
                                <Error text={formik.errors.ratio} />
                              )}
                            </VStack>
                          </HStack>
                          <HStack className="justify-end pt-1">
                            {formik.values.ratio > 0 && (
                              <p className="text-xs font-semi-medium text-gray-light">
                                {formik.values.ratio} bonus option(s) for every
                                1 outstanding option
                              </p>
                            )}
                          </HStack>
                        </VStack>
                        <VStack className="w-1/2">
                          <HStack className="items-center">
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Bonus Strike Price ({currencySymbol})
                            </Label>
                            <VStack className="gap-1 w-full">
                              <Input
                                type="number"
                                {...formik.getFieldProps("bonusStrikePrice")}
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                              <div>
                                {formik.touched.bonusStrikePrice &&
                                  formik.errors.bonusStrikePrice && (
                                    <Error
                                      text={formik.errors.bonusStrikePrice}
                                    />
                                  )}
                              </div>
                            </VStack>
                          </HStack>
                        </VStack>
                      </HStack>
                      <HStack className="space-between gap-4 items-center">
                        <VStack className="w-full">
                          <HStack className="items-center">
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Notes
                            </Label>
                            <VStack className="gap-1 w-full">
                              <TextArea
                                {...formik.getFieldProps("note")}
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                            </VStack>
                          </HStack>
                        </VStack>
                      </HStack>
                    </VStack>
                  </CollapsibleComponent>
                  {mode === "EditBonus" && (
                    <>
                      <CollapsibleComponent
                        label={"Bonus Notification Settings"}
                        labelDetail={""}
                        isOpen={accordionOpened !== "settings"}
                        onClick={() => setAccordionOpened("settings")}
                      >
                        <VStack className="gap-3 lg:w-[700px]">
                          <VStack className="w-full">
                            <HStack className="items-center justify-between w-full">
                              <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                                Notify Email to Employees
                              </Label>
                              <Switch
                                checked={
                                  formik.values.settings?.notifyEmployees
                                }
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "settings.notifyEmployees",
                                    !formik.values.settings?.notifyEmployees
                                  )
                                }
                                value={formik.values.settings?.notifyEmployees}
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                            </HStack>
                            <p className="text-xs font-semi-medium text-gray-light">
                              Send bonus email to the employees who were issued
                              with bonus grants
                            </p>
                          </VStack>
                          <VStack className="w-full">
                            <HStack className="items-center justify-between w-full">
                              <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                                Generate Bonus Grant Letters
                              </Label>
                              <Switch
                                checked={
                                  formik.values.settings
                                    ?.generateBonusGrantLetter
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "settings.generateBonusGrantLetter",
                                    !formik.values.settings
                                      ?.generateBonusGrantLetter
                                  );
                                  if (
                                    !formik.values.settings
                                      ?.generateBonusGrantLetter
                                  )
                                    formik.setFieldValue(
                                      "settings.useBonusGrantLetterTemplate",
                                      false
                                    );
                                }}
                                value={
                                  formik.values.settings
                                    ?.generateBonusGrantLetter
                                }
                                disabled={disableForm}
                                className={`${
                                  disableForm ? "cursor-not-allowed" : ""
                                }`}
                              />
                            </HStack>
                            <p className="text-xs font-semi-medium text-gray-light">
                              Generate grant letters for bonus grants that are
                              issued during the event
                            </p>
                          </VStack>
                          <HStack className="space-between gap-4 items-center pl-5">
                            <VStack className="w-1/2">
                              {formik.values.settings
                                ?.generateBonusGrantLetter && (
                                <VStack className="gap-2">
                                  <HStack className="items-center justify-between">
                                    <HStack className="gap-10">
                                      <HStack className="items-center gap-1">
                                        <input
                                          type="radio"
                                          className={`accent-orange-501 outline-hidden mr-1 ${
                                            disableForm
                                              ? "cursor-not-allowed"
                                              : "cursor-pointer"
                                          }`}
                                          checked={
                                            formik.values.settings
                                              ?.useBonusGrantLetterTemplate
                                          }
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              "settings.useBonusGrantLetterTemplate",
                                              e.target.value
                                            )
                                          }
                                          disabled={disableForm}
                                        />
                                        <Label className="mt-2 whitespace-nowrap">
                                          Use Custom Grant Letter Template
                                        </Label>
                                      </HStack>
                                      <HStack className="items-center gap-1">
                                        <input
                                          type="radio"
                                          className={`accent-orange-501 outline-hidden mr-1 ${
                                            disableForm
                                              ? "cursor-not-allowed"
                                              : "cursor-pointer"
                                          }`}
                                          checked={
                                            !formik.values.settings
                                              ?.useBonusGrantLetterTemplate
                                          }
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              "settings.useBonusGrantLetterTemplate",
                                              !e.target.value
                                            )
                                          }
                                          disabled={disableForm}
                                        />
                                        <Label className="mt-2 whitespace-nowrap">
                                          Use Grant Letter Template of parent
                                          Grant
                                        </Label>
                                      </HStack>
                                    </HStack>
                                  </HStack>
                                  {formik.values.settings
                                    ?.useBonusGrantLetterTemplate && (
                                    <HStack className="gap-2">
                                      {formik.values.bonusTemplates
                                        ?.bonusGrantLetterTemplateUrl && (
                                        <ViewReport
                                          s3url={
                                            formik.values.bonusTemplates
                                              ?.bonusGrantLetterTemplateUrl
                                          }
                                          documentName="Grant Letter Template.docx"
                                        />
                                      )}
                                      <ButtonPrimary1
                                        loading={isBonusGrantLetterLoading}
                                        disabled={disableForm}
                                        className={`${
                                          disableForm
                                            ? "cursor-not-allowed"
                                            : ""
                                        }`}
                                      >
                                        <label
                                          className={`${
                                            disableForm
                                              ? "cursor-not-allowed"
                                              : "cursor-pointer"
                                          }`}
                                        >
                                          <Input
                                            type="file"
                                            className="hidden"
                                            accept="application/docx"
                                            onChange={(e) => {
                                              setIsBonusGrantLetterLoading(
                                                true
                                              );
                                              handleBonusGrantLetterUpload(
                                                e,
                                                formik
                                              ).finally(() => {
                                                setIsBonusGrantLetterLoading(
                                                  false
                                                );
                                              });
                                            }}
                                            disabled={disableForm}
                                          />
                                          Upload Grant Letter Template
                                        </label>
                                      </ButtonPrimary1>
                                    </HStack>
                                  )}
                                </VStack>
                              )}
                            </VStack>
                          </HStack>
                        </VStack>
                      </CollapsibleComponent>
                      <CollapsibleComponent
                        label={"Bonus Documents"}
                        labelDetail={""}
                        isOpen={accordionOpened !== "documents"}
                        onClick={() => setAccordionOpened("documents")}
                      >
                        <HStack className="space-between gap-4 pt-4 lg:w-[700px]">
                          <div className="w-1/2">
                            {mode === "EditBonus" && corporateAction?.s3url && (
                              <VStack className="w-full">
                                <div>
                                  <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                                    Bonus Summary Report
                                  </Label>
                                  <BonusSummaryReport
                                    s3url={corporateAction?.s3url || ""}
                                  />
                                </div>
                              </VStack>
                            )}
                          </div>
                        </HStack>
                      </CollapsibleComponent>
                    </>
                  )}
                  <HStack className="justify-between mt-4">
                    <ButtonSecondary
                      className="h-8 px-2 py-1"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </ButtonSecondary>
                    <HStack className="gap-5">
                      {corporateAction?.state === "DRAFT" &&
                        mode === "EditBonus" && (
                          <>
                            <ButtonPrimary1
                              type="submit"
                              loading={isSaveLoading}
                              disabled={disableForm}
                            >
                              Save
                            </ButtonPrimary1>
                            <ApproveTooltip
                              text={
                                JSON.stringify(initialValues) !==
                                JSON.stringify(formik.values)
                                  ? "Please save details to approve"
                                  : "Approving will create the Bonus Grants"
                              }
                              _className="whitespace-nowrap"
                            >
                              <ButtonPrimary
                                onClick={() => {
                                  //Confirm Approve
                                  handleApproveBonus(formik.values);
                                }}
                                disabled={
                                  JSON.stringify(initialValues) !==
                                    JSON.stringify(formik.values) || disableForm
                                }
                              >
                                Approve
                              </ButtonPrimary>
                            </ApproveTooltip>
                          </>
                        )}
                      {mode === "AddBonus" && (
                        <ButtonPrimary type="submit" loading={isSaveLoading}>
                          Save
                        </ButtonPrimary>
                      )}
                    </HStack>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </>
  );
}

function ViewReport({
  s3url,
  documentName,
}: {
  s3url: string;
  documentName: string;
}) {
  const downloadDocumentName = `${getCompanyName()}_${documentName}`;
  const downloadFile = async () => {
    const blob = await (await fetch(s3url)).blob();
    downloadBlobObject(blob, downloadDocumentName);
  };
  return (
    <Tooltip text={"Click to download Report"}>
      <div
        className="cursor-pointer"
        onClick={() => {
          downloadFile();
        }}
      >
        <HStack className="items-center">
          <Icon icon="mdi:file-word" width={32} color="#1E90FF" />
          <p className="text-sm font-semi-medium hover:text-red-600 underline">
            {documentName}
          </p>
        </HStack>
      </div>
    </Tooltip>
  );
}

function ApproveTooltip(props: TooltipProps) {
  const { text, children, _className, disabled = false } = props;
  return (
    <div className="relative group ">
      {children}
      <div
        className={`absolute bottom-0 right-0 flex-col items-center hidden mb-6 rounded-md left-30 ${
          disabled ? "" : "group-hover:flex"
        }`}
      >
        <div
          className={`relative z-20 p-2 text-sm text-black shadow-lg bg-[#f6f9fb] ${_className}`}
        >
          {text}
        </div>
        <div className="w-3 h-3 -mt-2 ml-40 rotate-45 bg-[#f6f9fb]"></div>
      </div>
    </div>
  );
}
