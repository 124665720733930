/* eslint-disable func-names */
import React, { useEffect, useMemo, useState } from "react";
import { ChartData } from "chart.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Dialog, Switch } from "@mui/material";
import { Icon } from "@iconify/react";
import { addMonths, format } from "date-fns";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import {
  GrantedBy,
  useGrantDetailsStore,
  VestingScheduleData,
} from "../../../store/useGrantDetailsStore";
import SearchDropDown from "../../../components/shared/SearchDropdown";
import {
  useEmployees,
  useEsopPlans,
  useGetVestingOverrides,
  useVestingTemplates,
} from "../../../queries";
import { EmployementStatus } from "../../../types/Employee";
import { useCompanyStore } from "../../../store";
import canUserBeAssignedToThisPlan from "../../../utils/grantRule";
import { GrantPageCard } from "../grantInformation/GrantByOptionsPage";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import {
  extractDateFromTimeStamp,
  formatDisplayDate,
} from "../../../utils/date";
import { VestingDateType } from "../../../types/Grant";
import { Select } from "../../../components/shared/Select";
import {
  AddVestingMilestoneReq,
  MilestoneStatus,
  VestingMilestone,
} from "../../../types/milestone";
import { useGetAllMilestones } from "../../../queries/milestone";
import AddOrEditMilestone from "../../milestones/AddOrEditMilestone";
import { TriggerType, VestingType } from "../../../types/VestingTemplate";
import { BarChart } from "../../vestingSchedules/BarChart";
import { getFormattedValue } from "../../../utils/string";

interface GrantVestingScheduleProps {
  onStepChange: () => void;
  onBackClick: () => void;
}

const GrantVestingSchedule = (props: GrantVestingScheduleProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const [errors, setErrors] = useState<any>({});
  const { data: availableVestingOverrides } = useGetVestingOverrides();
  const { data: _allMilestones, isFetched } = useGetAllMilestones();
  const { data: vestingTemplates } = useVestingTemplates();
  const [includeMilestone, setIncludeMilestone] = useState(() => {
    if (
      data &&
      data.vestingScheduleData &&
      data.vestingScheduleData.some((v) => v.milestoneId !== "")
    ) {
      return true;
    } else return false;
  });
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  useEffect(() => {
    setAllMilestones(_allMilestones || []);
  }, [isFetched]);
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const vestingTypes = [
    {
      description: "Vesting From date of Grant",
      value: VestingDateType.GRANT_DATE,
    },
    {
      description: "Vesting From date of Joining",
      value: VestingDateType.EMPLOYEE_JOINING_DATE,
    },
    { description: "Custom Vesting", value: VestingDateType.CUSTOM_DATE },
  ];
  const availableActualVestingDays: string[] = useMemo(
    () => availableVestingOverrides?.data,
    [availableVestingOverrides]
  );

  const validationSchema = Yup.object().shape({
    selectedExistingTemplate: Yup.boolean().required(
      "No of Options is required"
    ),
    selectedVestingTemplate: Yup.string().when("selectedExistingTemplate", {
      is: true,
      then: Yup.string().required(
        "Vesting Template is required when selecting an existing template"
      ),
    }),
    manualTemplateName: Yup.string().when("selectedExistingTemplate", {
      is: false,
      then: Yup.string().test(
        "validate-manual-template-name",
        "Please select different name as same template name already exists",
        (value) => {
          if (value === "") return true;
          if (
            value &&
            vestingScheduleTemplates?.some(
              (v) => v.vestingTemplateName === value
            )
          )
            return false;
          return true;
        }
      ),
    }),
    vestingScheduleData: Yup.array()
      .when("selectedExistingTemplate", {
        is: false,
        then: Yup.array()
          .of(
            Yup.object().shape({
              vestingDate: Yup.string().required("Vesting Date is required"),
              percentage: Yup.number().required("Percentage is required"),
              vestingOptions: Yup.number().required(
                "Vesting Options are required"
              ),
              milestoneId: Yup.string().optional(),
            })
          )
          .required("Vesting Schedule Data is required")
          .test(
            "options-match",
            "Sum of Vesting Options must match Options Granted",
            function (vestingScheduleData) {
              const { optionsGranted, isFractional } = this.parent || {};

              if (!vestingScheduleData || !optionsGranted) return true;
              const totalVestingOptions = vestingScheduleData.reduce(
                (sum, item) => sum + (item.vestingOptions || 0),
                0
              );

              return isFractional
                ? parseFloat(totalVestingOptions.toFixed(4)) === optionsGranted
                : parseInt(totalVestingOptions.toString(), 10) ===
                    optionsGranted;
            }
          ),
      })
      .test(
        "vesting-date-check",
        "Vesting Date cannot be earlier than the Grant Date",
        function (vestingScheduleData) {
          const { dateOfGrant } = this.parent || {};

          if (!vestingScheduleData || !dateOfGrant) return true;

          const isValid = vestingScheduleData.every((item) => {
            const vestingDate = new Date(item?.vestingDate || new Date());
            const grantDateObj = new Date(dateOfGrant);

            return vestingDate >= grantDateObj;
          });

          return isValid;
        }
      ),
  });

  useEffect(() => {
    if (
      data.vestingTemplate &&
      data.vestingTemplate.id &&
      data.vestingTemplate.id !== "" &&
      data.vestingTemplate.vestingType !== VestingType.MANUAL
    ) {
      handleChangeOfVestingTemplateForExisting(data.vestingTemplate.id);
    }
  }, [data?.vestingTemplate]);

  const handleChangeOfVestingTemplateForManual = (e: string) => {
    const value = vestingScheduleTemplates?.find((v) => v.id === e);
    if (value) {
      data.setTemplateToEdit(value);
      const vestingScheduleData: VestingScheduleData[] = generateProjections(
        value.schedules,
        new Date(data.dateOfGrant || new Date()),
        value.cliffPeriod,
        data.optionsGranted || 1,
        data.plan?.isFractional || false
      );
      data.setVestingScheduleData(
        vestingScheduleData
          .filter((v) => v.vestingOptions > 0)
          .map((v) => ({
            ...v,
            percentage: parseFloat((v.percentage * 100).toFixed(2)),
          }))
      );
    }
  };

  const handleChangeOfVestingTemplateForExisting = (e: string) => {
    const value = vestingScheduleTemplates?.find((v) => v.id === e);
    if (value) {
      data.setVestingTemplate(value);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function handleSubmit() {
    formik
      .validateForm({
        selectedExistingTemplate: data.selectedExistingTemplate,
        selectedVestingTemplate: data?.vestingTemplate?.id || "",
        optionsGranted: data?.optionsGranted,
        vestingScheduleData: data?.vestingScheduleData,
        dateOfGrant: data?.dateOfGrant,
        isFractional: data?.plan?.isFractional || false,
        manualTemplateName: data?.manualTemplateName,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  function getVestingDate() {
    const vestingDate =
      !data.vestingDateType ||
      data.vestingDateType === VestingDateType.GRANT_DATE
        ? new Date(data.dateOfGrant)
        : data.vestingDateType === VestingDateType.EMPLOYEE_JOINING_DATE
        ? new Date(data.employee.dateOfJoin)
        : new Date(data.vestingDate);

    if (!data.actualVestingDay || data.actualVestingDay === "SAME_DAY") {
      return vestingDate;
    } else if (data.actualVestingDay === "NEXT_DAY") {
      vestingDate.setDate(vestingDate.getDate() + 1);
      return vestingDate;
    } else if (data.actualVestingDay === "PREVIOUS_DAY") {
      vestingDate.setDate(vestingDate.getDate() - 1);
      return vestingDate;
    } else if (data.actualVestingDay === "STARTING_OF_MONTH") {
      vestingDate.setDate(1);
      return vestingDate;
    } else if (data.actualVestingDay === "END_OF_MONTH") {
      vestingDate.setMonth(vestingDate.getMonth() + 1);
      vestingDate.setDate(0);
      return vestingDate;
    }
    return vestingDate;
  }
  const currency = getCurrencyType();

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Vesting Schedule
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Enter the vesting details that define when the granted options will
          become eligible for exercise.
        </HStack>
      </VStack>
      <HStack className="gap-4 p-4">
        <GrantPageCard
          header="Grant Date"
          value={formatDisplayDate(data.dateOfGrant || new Date())}
        />
        <GrantPageCard
          header="Granted Options"
          value={(data.optionsGranted || 0).toLocaleString(currencyType, {
            maximumFractionDigits: 4,
          })}
        />
        <GrantPageCard
          header="Options Available"
          value={(data?.plan?.optionsReserved || 0).toLocaleString(
            currencyType,
            {
              maximumFractionDigits: 4,
            }
          )}
        />
        <GrantPageCard header="Conversion Ratio" value={data.conversionRatio} />
      </HStack>
      <VStack className="gap-4 px-6 pt-7">
        <HStack className="gap-8 p ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Choose Template Type</Label>
            <Select
              placeholder="--Select--"
              options={["Existing Template", "Manually Create Template"]}
              value={
                data?.selectedExistingTemplate
                  ? "Existing Template"
                  : "Manually Create Template"
              }
              onChange={(e) => {
                if (e.target.value === "") return;
                data.setSelectedExistingTemplate(
                  e.target.value === "Existing Template"
                );
              }}
            />
          </div>
          {data.selectedExistingTemplate ? (
            <div className="flex-1"></div>
          ) : (
            <div className="flex-1">
              <Label className="text-sm font-normal">Choose Template</Label>
              <Select
                placeholder="--Select template to edit--"
                options={vestingScheduleTemplates || []}
                value={data?.templateToEdit?.id || ""}
                textGetter={(e) => e.vestingTemplateName}
                valueGetter={(e) => e.id}
                onChange={(e) =>
                  handleChangeOfVestingTemplateForManual(e.target.value)
                }
              />
            </div>
          )}
        </HStack>
        {data.selectedExistingTemplate ? (
          <>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Vesting Schedule</Label>
                <Select
                  placeholder="--Select--"
                  options={vestingScheduleTemplates || []}
                  value={data?.vestingTemplate?.id || ""}
                  textGetter={(e) => e.vestingTemplateName}
                  valueGetter={(e) => e.id}
                  onChange={(e) => {
                    handleChangeOfVestingTemplateForExisting(e.target.value);
                  }}
                />
                {errors.selectedVestingTemplate && (
                  <Error text={errors.selectedVestingTemplate} />
                )}
              </div>
              <div className="flex-1"></div>
            </HStack>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Vesting Date Type</Label>
                <Select
                  options={vestingTypes}
                  value={data.vestingDateType || ""}
                  textGetter={(option) => option?.description}
                  valueGetter={(value) => value?.value}
                  onChange={(e) => {
                    const type = e.target.value as VestingDateType;
                    data.setVestingDateType(type);
                    if (type === VestingDateType.CUSTOM_DATE) {
                      data.setActualVestingDay("SAME DAY");
                      data.setVestingDate(data.dateOfGrant);
                    } else {
                      data.setVestingDate("");
                    }
                  }}
                />
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Actual Vesting Day
                </Label>
                <Select
                  options={availableActualVestingDays || []}
                  value={data.actualVestingDay}
                  isPlaceHolderDisabled={true}
                  textGetter={(option) => option.split("_").join(" ")}
                  valueGetter={(value) => value}
                  disabled={
                    data.vestingDateType === VestingDateType.CUSTOM_DATE
                  }
                  onChange={(e) => {
                    if (data.vestingDateType === VestingDateType.CUSTOM_DATE)
                      return;
                    data.setActualVestingDay(e.target.value);
                  }}
                />
              </div>
            </HStack>
            {data.vestingDateType === VestingDateType.CUSTOM_DATE && (
              <HStack className="gap-8 ">
                <div className="flex-1">
                  <Label
                    className={`text-sm font-normal ${
                      data.vestingDateType !== VestingDateType.CUSTOM_DATE &&
                      "text-gray-400"
                    }`}
                  >
                    Select a Date
                  </Label>
                  <Input
                    type="date"
                    disabled={
                      data.vestingDateType !== VestingDateType.CUSTOM_DATE
                    }
                    name="vestingDate"
                    value={data.vestingDate}
                    onChange={(e) => {
                      if (e.target.value !== "")
                        data.setVestingDate(
                          format(new Date(e.target.value), "yyyy-MM-dd")
                        );
                    }}
                  />
                </div>
                <div className="flex-1"></div>
              </HStack>
            )}
          </>
        ) : (
          <>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Template Name</Label>
                <Input
                  type="text"
                  onChange={(e) => data.setManualTemplateName(e.target.value)}
                  value={data.manualTemplateName}
                />
                {errors.manualTemplateName && (
                  <Error text={errors.manualTemplateName} />
                )}
              </div>
              <div className="items-center justify-between flex-1">
                <VStack>
                  <HStack className="flex-row-reverse items-center justify-end gap-6 pt-6">
                    <Switch
                      checked={includeMilestone}
                      onChange={() => {
                        setIncludeMilestone(!includeMilestone);
                        if (
                          data.vestingScheduleData &&
                          data.vestingScheduleData.length > 0
                        ) {
                          const vestingData = data.vestingScheduleData.map(
                            (v) => {
                              v.milestoneId = "";
                              return v;
                            }
                          );
                          data.setVestingScheduleData(vestingData);
                        }
                      }}
                      value={includeMilestone}
                    />
                    <Label className="text-sm font-normal">
                      Include Milestone Vesting
                    </Label>
                  </HStack>
                  {includeMilestone && (
                    <HStack className="text-xxs text-[#668DB1] p-2 items-center bg-[#EAF0FA] rounded-md">
                      <HStack>
                        <Icon icon="ep:warning" color="#668DB1" height={18} />
                      </HStack>
                      <div className="px-2">
                        <span>
                          If you would like to create new milestone-based
                          vesting, click on&nbsp;
                          <u>
                            <button
                              onClick={() => setDialog({ open: !dialog.open })}
                            >
                              <b className="italic underline">{` Create Milestone `}</b>
                            </button>
                          </u>
                        </span>
                        <span>&nbsp;and provide the milestone criteria</span>
                      </div>
                    </HStack>
                  )}
                </VStack>
              </div>
            </HStack>
            <p className="italic text-xxs">{`Note: Cliff Period validation won't be there. Please make sure it was included while creating`}</p>
            <VStack className="pt-6">
              <ManualSchedulesData
                includeMilestone={includeMilestone}
                setDialog={setDialog}
              />
              {errors.vestingScheduleData && (
                <Error text={errors.vestingScheduleData} />
              )}
            </VStack>
            <HStack>
              <ButtonPrimary1
                onClick={() =>
                  data.addVestingScheduleData({
                    percentage: 0,
                    vestingDate: format(new Date(), "yyyy-MM-dd"),
                    vestingOptions: 0,
                    milestoneId: "",
                  })
                }
              >
                Add Schedule
              </ButtonPrimary1>
            </HStack>
          </>
        )}
        <Dialog open={dialog.open} maxWidth="md">
          <AddOrEditMilestone
            onClose={() => setDialog({ open: false })}
            data={undefined}
            mode="Add"
          />
        </Dialog>

        {((data.selectedExistingTemplate &&
          data.vestingTemplate &&
          data.vestingTemplate.schedules) ||
          (!data.selectedExistingTemplate &&
            data.vestingScheduleData &&
            data.vestingScheduleData.length > 0)) && (
          <VStack className="flex">
            <HStack className="flex justify-end">
              {!showTable ? (
                <button
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  <Icon
                    className="hover:text-orange-501"
                    icon="mdi:table"
                    height={25}
                  />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setShowTable(!showTable);
                  }}
                >
                  <Icon
                    icon="mdi:graph-bar"
                    className="hover:text-orange-501"
                    height={25}
                  />
                </button>
              )}
            </HStack>
            <HStack className="items-center pt-20 mb-10 px-44 grow">
              {!showTable ? (
                <ProjectionChartForVesting
                  vestings={
                    data.selectedExistingTemplate
                      ? generateProjections(
                          data.vestingTemplate.schedules,
                          getVestingDate(),
                          data.vestingTemplate.cliffPeriod,
                          data.optionsGranted,
                          data.plan.isFractional
                        )
                      : data.vestingScheduleData
                  }
                  isFractional={data.plan?.isFractional || false}
                />
              ) : (
                <AGGridEmployeeScheduleTable
                  data={
                    data.selectedExistingTemplate
                      ? generateProjections(
                          data.vestingTemplate.schedules,
                          getVestingDate(),
                          data.vestingTemplate.cliffPeriod,
                          data.optionsGranted,
                          data.plan.isFractional
                        )
                      : data.vestingScheduleData
                  }
                  currency={currency}
                  optionsGranted={data.optionsGranted || 0}
                  fractional={data.plan.isFractional || false}
                />
              )}
            </HStack>
          </VStack>
        )}

        <HStack className="justify-between pt-4">
          <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

function AGGridEmployeeScheduleTable(props: {
  data: VestingScheduleData[];
  currency: string;
  optionsGranted: number;
  fractional: boolean;
}) {
  const { data, currency, optionsGranted, fractional } = props;
  const rowData = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        grantedOptions: optionsGranted,
      })),
    [data]
  );
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "VESTING DATE",
        field: "vestingDate",
        sortable: true,
        autoHeight: true,
        wrapText: true,
        minWidth: 200,
        width: 200,
        filterValueGetter: (e) => new Date(e.getValue("vestingDate")).getDate(),
        filter: "agDateColumnFilter",
        suppressAutoSize: false,
        suppressSizeToFit: true,
        valueFormatter: (e) => formatDisplayDate(e.value),
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "GRANTED",
        field: "grantedOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        filter: "agNumberColumnFilter",
        valueFormatter: (e) => getFormattedValue(e.value, currency, fractional),
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "VESTED",
        field: "vestingOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        valueFormatter: (e) => getFormattedValue(e.value, currency, fractional),
        filter: "agMultiColumnFilter",
      },
    ],
    []
  );

  return (
    <HStack className="justify-between w-full ">
      <Box
        style={{
          height: `${Math.min(
            400,
            (rowData.length >= 10 ? 10 : rowData.length + 3) * 100
          )}px`,
        }}
        className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
      >
        <AgGridReact
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
        />
      </Box>
    </HStack>
  );
}

function ManualSchedulesData({
  includeMilestone = false,
  setDialog,
}: {
  includeMilestone: boolean;
  setDialog: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
    }>
  >;
}) {
  const data = useGrantDetailsStore();

  const { data: _allMilestones, isFetched, isFetching } = useGetAllMilestones();
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);

  const createMilestone: VestingMilestone = {
    id: "Create New Milestone",
    targetDate: new Date(),
    completionDate: new Date(),
    milestoneName: "Create New Milestone",
    milestoneStatus: MilestoneStatus.DRAFT,
  };

  useEffect(() => {
    if (_allMilestones) {
      const milestones = [
        createMilestone,
        ..._allMilestones.filter(
          (m) => m.milestoneStatus !== MilestoneStatus.COMPLETED
        ),
      ];
      setAllMilestones(milestones);
    }
  }, [isFetched, data, isFetching]);

  return (
    <HStack className="py-4 border border-gray-200 rounded-sm">
      <table aria-label="header" className="w-full ">
        <thead>
          <tr
            className={`p-2 ${
              data.vestingScheduleData?.length === 0 &&
              "border-b border-gray-200"
            }`}
          >
            {includeMilestone && (
              <th className="flex-1 w-2/6 pl-3 text-sm font-normal text-left">
                MILESTONE NAME
              </th>
            )}
            <th
              className={`flex-1 pl-3 text-sm font-normal text-left ${
                includeMilestone ? "w-1/6" : "w-1/3"
              }`}
            >
              DATE
            </th>
            <th
              className={`flex-1 pl-3 text-sm font-normal text-left ${
                includeMilestone ? "w-1/6" : "w-1/3"
              }`}
            >
              PERCENTAGE (%)
            </th>
            <th
              className={`flex-1 pl-3 text-sm font-normal text-left ${
                includeMilestone ? "w-1/6" : "w-1/3"
              }`}
            >
              NO. OF OPTIONS
            </th>
            <th className="flex-1 w-1/12"></th>
          </tr>
        </thead>
        <tbody>
          {data.vestingScheduleData?.map((_, index) => {
            const scheduleData = data.vestingScheduleData[index];
            return (
              <tr
                className="border-t border-gray-200 border-dashed"
                key={index}
              >
                {includeMilestone && (
                  <td className="p-2">
                    <div className="flex flex-col">
                      <Select
                        placeholder="Select a milestone"
                        value={scheduleData.milestoneId || ""}
                        options={allMilestones || []}
                        valueGetter={(o) => o.id}
                        textGetter={(o) => o.milestoneName}
                        onChange={(e) => {
                          if (e.target.value === createMilestone.id) {
                            setDialog({ open: true });
                          } else {
                            data.changeVestingScheduleData(
                              index,
                              e.target.value,
                              "milestoneId"
                            );
                            if (e.target.value !== "") {
                              const milestone = allMilestones.find(
                                (m) => m.id === e.target.value
                              );
                              if (milestone) {
                                data.changeVestingScheduleData(
                                  index,
                                  new Date(
                                    milestone.targetDate
                                  ).toLocaleDateString("en-CA"),
                                  "vestingDate"
                                );
                              }
                            }
                          }
                        }}
                      />
                      {!scheduleData.milestoneId ? (
                        <p className="pl-2 text-[8px] text-[#668DB1]">
                          If anything is not selected , it will consider as Time
                          Vesting
                        </p>
                      ) : (
                        <p className="pl-2 text-[8px] text-[#668DB1]">&nbsp;</p>
                      )}
                    </div>
                  </td>
                )}
                <td className="p-2">
                  <div>
                    <Input
                      type="date"
                      value={scheduleData.vestingDate}
                      disabled={scheduleData.milestoneId !== ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "")
                          data.changeVestingScheduleData(
                            index,
                            value,
                            "vestingDate"
                          );
                      }}
                    />
                  </div>
                  <p className="pl-2 text-[8px] text-[#668DB1]">&nbsp;</p>
                </td>
                <td className="p-2">
                  <div>
                    <Input
                      type="number"
                      value={scheduleData.percentage}
                      onChange={(e) => {
                        const optionsGranted = data?.optionsGranted || 0;
                        const options = data?.plan?.isFractional
                          ? parseFloat(
                              (
                                (optionsGranted * parseFloat(e.target.value)) /
                                100
                              ).toFixed(4)
                            )
                          : parseInt(
                              (
                                (optionsGranted * parseFloat(e.target.value)) /
                                100
                              ).toFixed(4),
                              10
                            );
                        data.changeVestingScheduleData(
                          index,
                          parseFloat(e.target.value),
                          "percentage"
                        );
                        data.changeVestingScheduleData(
                          index,
                          options,
                          "vestingOptions"
                        );
                      }}
                      onBlur={(e) => {
                        const optionsGranted = data?.optionsGranted || 0;
                        const options = scheduleData.vestingOptions;
                        const percentage = parseFloat(
                          ((options / optionsGranted) * 100).toFixed(2)
                        );
                        data.changeVestingScheduleData(
                          index,
                          percentage,
                          "percentage"
                        );
                      }}
                    />
                    <p className="pl-2 text-[8px] text-[#668DB1]">&nbsp;</p>
                  </div>
                </td>
                <td className="p-2">
                  <div>
                    <Input
                      type="number"
                      value={scheduleData.vestingOptions}
                      onChange={(e) => {
                        const optionsGranted = data?.optionsGranted || 0;
                        const percentage = parseFloat(
                          (
                            (parseFloat(e.target.value) / optionsGranted) *
                            100
                          ).toFixed(2)
                        );
                        data.changeVestingScheduleData(
                          index,
                          data?.plan?.isFractional
                            ? parseFloat(e.target.value)
                            : parseInt(e.target.value, 10),
                          "vestingOptions"
                        );
                        data.changeVestingScheduleData(
                          index,
                          percentage,
                          "percentage"
                        );
                      }}
                    />
                    <p className="pl-2 text-[8px] text-[#668DB1]">&nbsp;</p>
                  </div>
                </td>
                <td className="text-center">
                  <div>
                    <button
                      onClick={() => data.deleteVestingScheduleData(index)}
                      className="justify-center text-zinc-300 hover:scale-105"
                    >
                      <DeleteIcon />
                    </button>
                    <p className="pl-2 text-[8px] text-[#668DB1]">&nbsp;</p>
                  </div>
                </td>
              </tr>
            );
          })}
          {data.vestingScheduleData?.length === 0 && (
            <tr>
              <td
                colSpan={3}
                className="pt-6 pb-6 text-center align-middle border-t border-gray-200"
              >
                <div
                  style={{ width: "200px", margin: "auto" }}
                  className="p-2 border-2 border-solid border-black-501 bg-yellow-50"
                >
                  No Rows To Show
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </HStack>
  );
}

function ProjectionChartForVesting({
  vestings,
  isFractional,
}: {
  vestings: VestingScheduleData[];
  isFractional: boolean;
}) {
  let totalOptions = 0;
  const date = new Date();
  const vestingDataSets = vestings.map((v) => {
    const backGroundColor =
      new Date(v.vestingDate) < date ? "#97C2E8" : "#D8E3F6";
    totalOptions += v.vestingOptions;
    return {
      options: isFractional
        ? parseFloat(totalOptions.toFixed(4))
        : parseInt(totalOptions.toString(), 10),
      backGroundColor,
    };
  });
  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(new Date(v.vestingDate), "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestingDataSets.map((v) => v.options),
        backgroundColor: vestingDataSets.map((v) => v.backGroundColor),
      },
    ],
  };

  return <BarChart data={data} isFractional={isFractional} />;
}

function generateProjections(
  schedules: any,
  vestingStartDate: Date,
  cliffPeriod: number,
  optionsGranted: number,
  isFractional: boolean
) {
  const baseVesting = {
    cliffDate: false,
    date: new Date(),
    intervalsPassed: 0,
    vestedPercentage: 0,
    isVestDate: false,
    vestedOptions: 0,
    accumulatedVestingPercentageForGrant: 0,
    accumulatedVestedOptionsForGrant: 0,
  };
  schedules.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
  const vestings = [];
  let accumulatedVestingPercentageBeforeCliff = 0;
  let intervalsPassed = 0;
  let vestedOptions = 0;
  let vestingPercentage = 0;
  let accumulatedVestedOptionsForGrant = 0;
  let accumulatedVestingPercentageForGrant = 0;
  let vestedOptionsSoFarForGrant = 0;
  let currentDate = vestingStartDate;
  for (const schedule of schedules) {
    const schedulePercentage = schedule.percentage / 100;
    if (schedule.vestingTriggerType === TriggerType.TIME) {
      let noOfEventsInVestingSequence = 1;
      if (schedule.vestingDuration === 0 || schedule.vestingInterval === 0) {
        noOfEventsInVestingSequence = 1;
      } else {
        noOfEventsInVestingSequence =
          schedule.vestingDuration / schedule.vestingInterval;
      }
      for (let event = 1; event <= noOfEventsInVestingSequence; event++) {
        const vesting = { ...baseVesting };
        intervalsPassed += schedule.vestingInterval;
        if (intervalsPassed < cliffPeriod) {
          accumulatedVestingPercentageBeforeCliff +=
            schedulePercentage / noOfEventsInVestingSequence;
        } else {
          if (intervalsPassed === cliffPeriod) {
            vesting.cliffDate = true;
          }
          vestingPercentage =
            schedulePercentage / noOfEventsInVestingSequence +
            accumulatedVestingPercentageBeforeCliff;
          accumulatedVestingPercentageBeforeCliff = 0;
          currentDate = addMonths(vestingStartDate, intervalsPassed);
          vesting.date = currentDate;
          vesting.intervalsPassed = intervalsPassed;
          vesting.vestedPercentage = vestingPercentage;
          vesting.isVestDate = true;
          vestings.push(vesting);
        }
      }
    } else if (schedule.vestingTriggerType === TriggerType.EVENT) {
      const vesting = { ...baseVesting };
      vesting.date = new Date(
        schedule.eventCompletionDate || schedule.eventTargetDate || "1900-01-01"
      );
      vesting.vestedPercentage = schedulePercentage;
      vesting.isVestDate = true;
      vestings.push(vesting);
    }
  }
  for (const vesting of vestings) {
    accumulatedVestingPercentageForGrant += vesting.vestedPercentage;
    if (isFractional) {
      accumulatedVestedOptionsForGrant = parseFloat(
        (accumulatedVestingPercentageForGrant * optionsGranted).toFixed(4)
      );
      vestedOptions = parseFloat(
        (accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant).toFixed(
          4
        )
      );
    } else {
      accumulatedVestedOptionsForGrant = Math.floor(
        roundOptions(roundPercentage(accumulatedVestingPercentageForGrant)) *
          optionsGranted
      );
      vestedOptions =
        accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant;
    }
    vestedOptionsSoFarForGrant += vestedOptions;
    vesting.vestedOptions = vestedOptions;
    vesting.accumulatedVestingPercentageForGrant =
      accumulatedVestingPercentageForGrant;
    vesting.accumulatedVestedOptionsForGrant = accumulatedVestedOptionsForGrant;
  }
  const returnVestings: VestingScheduleData[] = vestings.map((v) => ({
    percentage: v.vestedPercentage,
    vestingDate: format(v.date, "yyyy-MM-dd"),
    vestingOptions: v.vestedOptions,
  }));
  return returnVestings;
}

function roundPercentage(x: number) {
  return roundToPlaces(x, 10);
}

function roundOptions(x: number) {
  return roundToPlaces(x, 3);
}

function roundToPlaces(x: number, places: number) {
  const scaledNumber = x * 10 ** places;
  return Math.round(scaledNumber) / 10 ** places;
}

export default GrantVestingSchedule;
