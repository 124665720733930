import React from "react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

import Icon from "@mui/material/Icon";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import SearchInput from "../../shared/SearchInput";

const Documents = () => {
  const handleSubmit = () => {};
  return (
    <>
      <HStack className="justify-between m-6">
        <Box>Documents</Box>
        <HStack>
          <Box className="mr-4">
            <SearchInput
              className=""
              type=""
              placeholder=""
              value=""
              onClick={handleSubmit}
              name=""
            />
          </Box>
          <ButtonPrimary className="px-2 py-1 h-11">File Manager</ButtonPrimary>
        </HStack>
      </HStack>
      <HStack className="justify-start p-4">
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle mt-15">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-between align-middle mt-15">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle mt-15">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
      </HStack>
      <HStack className="justify-start p-4">
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
        <Box className="w-40 h-40 m-4 bg-white">
          <VStack className="items-center justify-center align-middle">
            <FolderOpenIcon />
            <p>Grants</p>
          </VStack>
        </Box>
      </HStack>
    </>
  );
};

export default Documents;
