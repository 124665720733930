import { differenceInYears } from "date-fns/esm";
import * as _ from "lodash";
import { useMutation, useQuery } from "react-query";
import {
  addEmplooyeesViaExcel,
  addEmployee,
  addEmployeeExcel,
  completeResignation,
  deleteEmployee,
  discardResignation,
  editEmployee,
  employeeResignation,
  generateEmployeeCredentials,
  getAllDocuments,
  getAllSubsidiaryCompanies,
  getEmployeePageDetails,
  getEmployeeTemplate,
  getEmployeeTransactionDetails,
  getEmployeesOverview,
  getExistingExcelConfigs,
  getGrantPPS,
  getResignationDetails,
  remindOfferedEmployee,
  resignationProcess,
  revokeOrEnableEmployeeAccess,
  saveEmployeeData,
  transferEmployeeAccess,
  validateAndImportCorrecrtedData,
} from "../api/Esop";
import { _trimAll } from "../utils/string";
import { queryClient } from "./client";
import { randomEmployeeOverview } from "./placeholders";
import {
  getExsitingGreytipConfigs,
  syncHrms,
  testHrmsConfig,
} from "../api/employeeImport";
import { EmployeeStockTransaction } from "../types/VestingTemplate";

export function useEmployees() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: async () => {
      const response = await getEmployeesOverview();
      return response.data;
    },
    select: (data) => {
      data.employees.sort(
        (a, b) =>
          new Date(b.createdAt || 0).valueOf() -
          new Date(a.createdAt || 0).valueOf()
      );
      return data.employees.map((employee) => {
        const lastWorkDay = employee.lastDay
          ? new Date(employee.lastDay)
          : new Date();
        return {
          ...employee,
          experience: differenceInYears(new Date(), lastWorkDay),
        };
      });
    },
    placeholderData: randomEmployeeOverview(),
  });
}
export function useEmployeesDepartment() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: async () => {
      const response = await getEmployeesOverview();
      return response.data;
    },
    select: (data) => data,
  });
}

export function useEmployeeStatus() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: async () => {
      const response = await getEmployeesOverview();
      return response.data;
    },
    select: (data) =>
      _.compact(
        _.uniq(data.employees.map((status) => status.employmentStatus))
      ) || [],
  });
}

export function useEmployeeDepartment() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: async () => {
      const response = await getEmployeesOverview();
      return response.data;
    },
    select: ({ employees }) => {
      employees.sort((a, b) => (a.department as any) - (b.department as any));
      const uniqEmployeesByDepartments = _.uniqBy(employees, (employee) =>
        _trimAll(employee.department.toLowerCase())
      );
      return uniqEmployeesByDepartments
        .map((employee) => _trimAll(employee.department))
        .sort();
    },
  });
}

export function useEmployeesJoiningDateRange() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: async () => {
      const response = await getEmployeesOverview();
      return response.data;
    },
    select: ({ employees }) => {
      const joiningDates = employees?.map(
        (employee) => new Date(employee.dateOfJoin)
      );
      const dateOfJoinStart = _.min(joiningDates);
      const dateOfJoinEnd = _.max(joiningDates);
      return { dateOfJoinStart, dateOfJoinEnd };
    },
  });
}

export function useAddEmployee() {
  return useMutation({
    mutationKey: "addEmployee",
    mutationFn: addEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
      queryClient.refetchQueries("esopPlansSummary");
    },
    onError: () => {},
  });
}

export function useEditEmployee() {
  return useMutation({
    mutationKey: "editEmployee",
    mutationFn: editEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useEmployeeTemplate() {
  return useQuery({
    queryKey: "employeeTemplate",
    queryFn: getEmployeeTemplate,
    select: (data) => data.data.s3link,
  });
}

export function useAddEmployeeExcel() {
  // name needs to change
  return useMutation({
    mutationKey: "addEmployeeExcel",
    mutationFn: addEmployeeExcel,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useDeleteEmployee() {
  return useMutation({
    mutationKey: "deleteEmployee",
    mutationFn: deleteEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: () => {},
  });
}

export function useRevokeOrEnableAccess() {
  return useMutation({
    mutationKey: "revokeOrEnableEmployeeAccess",
    mutationFn: revokeOrEnableEmployeeAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
    },
  });
}

export function useEmployeeAcceptReminder() {
  return useMutation({
    mutationKey: "remindOfferedEmployee",
    mutationFn: remindOfferedEmployee,
  });
}

export function useEmployeePageDetails(empId: string) {
  return useQuery({
    queryKey: ["employeePageDetails", empId],
    queryFn: async () => (await getEmployeePageDetails(empId)).data,
    select: (data) => data,
  });
}

export function useEmployeeTransactionDetails(id: string) {
  return useQuery({
    queryKey: ["employeeTransactionDetails", id],
    queryFn: async () => {
      const data = (await getEmployeeTransactionDetails(id)).data as any;
      return data.transactions as EmployeeStockTransaction[];
    },
  });
}

export function useSubsidiaryCompanyNames() {
  return useQuery({
    queryKey: "getAllSubsidiaryCompanyNames",
    queryFn: getAllSubsidiaryCompanies,
  });
}
export function useGenerateEmployeeCredentials() {
  return useMutation({
    mutationKey: "nomutationKeyBecauseNoCache",
    mutationFn: generateEmployeeCredentials,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
    },
  });
}

export function useEmployeeResignation() {
  return useMutation({
    mutationKey: "employeeResignation",
    mutationFn: employeeResignation,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useResignationProcess() {
  return useMutation({
    mutationKey: "resignationProcess",
    mutationFn: resignationProcess,
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useGetResignationDetails(employeeId: string) {
  return useQuery({
    queryKey: ["getResignationDetails", employeeId],
    queryFn: async () => (await getResignationDetails(employeeId)).data,
  });
}

export function useCompleteResignation() {
  return useMutation({
    mutationKey: "completeResignation",
    mutationFn: completeResignation,
    onSuccess: () => {
      queryClient.invalidateQueries("getResignationDetails");
      queryClient.refetchQueries("getResignationDetails");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("allGrants");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useEmployeeUploadViaExcel() {
  // names needs to change
  return useMutation({
    mutationKey: "employeeUploadViaExcel",
    mutationFn: addEmplooyeesViaExcel,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: {
      response: { data: { reason: string; errorRaw: string } };
    }) => {},
  });
}
export function useSaveEmployeeData() {
  return useMutation({
    mutationKey: "saveEmployeeData",
    mutationFn: saveEmployeeData,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}
export function useGetEmployeeUploadConfigs() {
  return useQuery({
    queryKey: "EmployeeUIloadConfigs",
    queryFn: getExistingExcelConfigs,
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}
export function useValidateAndImportEmployee() {
  return useMutation({
    mutationKey: "validateAndImportEmployee",
    mutationFn: validateAndImportCorrecrtedData,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
      queryClient.refetchQueries("esopPlansSummary");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}
export function useDiscardResignation() {
  return useMutation({
    mutationKey: "discardResignation",
    mutationFn: discardResignation,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useDocuments() {
  return useQuery({
    queryKey: "documents",
    queryFn: async () => {
      const response = await getAllDocuments();
      return response.data;
    },
    staleTime: 1000 * 60 * 10,
  });
}
export function useGetGreyTipConfigs() {
  return useQuery({
    queryKey: "getGreyTipConfigs",
    queryFn: getExsitingGreytipConfigs,
    retry: false,
  });
}

export function useTestHrmsConfig() {
  return useMutation({
    mutationKey: "testHrmsConfig",
    mutationFn: testHrmsConfig,
  });
}
export function usesyncHrms() {
  return useMutation({
    mutationKey: "syncHrms",
    mutationFn: syncHrms,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
  });
}

export function useTransferEmployeeAccess() {
  return useMutation({
    mutationKey: "transferAccess",
    mutationFn: transferEmployeeAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
  });
}

export function useGetGrantPPS(date: string) {
  return useQuery({
    queryKey: ["getGrantPPS", date],
    queryFn: async () => (await getGrantPPS(date)).data,
  });
}
